import wx from 'weixin-js-sdk'
import { Dialog } from 'vant'
import { getWxConfigApi, getUserInfoApi } from '@/api/weixin'
import { APPID } from '@/const'

export function config() {
  let url = window.location.href.split('#')[0]
  getWxConfigApi({ url }).then(res => {
    wx.config({
      debug: false,
      ...res.data,
      jsApiList: [ // 项目所有要用到的接口都要在这里声明
        'scanQRCode',
        'chooseImage'
      ]
    })
    wx.ready(function() {
      // config信息验证成功后会执行ready方法，
      // 所有接口调用都必须在config接口获得结果之后，
      // config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。
      // 对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      console.log('调用了ready')
    })
    wx.error(function(res) {
      // config信息验证失败会执行error函数，
      // 如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，
      // 对于SPA可以在这里更新签名。
      // console.log('调用了error', res)
    })
  })
}

export function setUserInfo(params) {
  getUserInfoApi(params).then(res => {
    localStorage.setItem('userInfo', JSON.stringify(res.data))
  }).catch(() => {
    Dialog.confirm({
      message: '服务器错误',
    })
  })
}

export function getAuth() {
  const redirect_uri = encodeURI(window.location.href)
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
}
