<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { config, getAuth } from '@/wx'
import { APPID } from '@/const'
import { getUserInfoApi } from '@/api/weixin'
import { Dialog } from 'vant'

export default {
  metaInfo: {
    title: '诺因检验',
    meta: [
      { charset: 'utf-8' },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
      }
    ]
  },
  created() {
    // config()
    // this.refreshUserInfo()
    // getAuth()
  },
  methods: {}
}
</script>
