// 自动生成
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
// 全局css
import '@/styles/base.css'
// vant
import Vant, { Dialog } from 'vant'
// import 'vant/lib/index.css'
import 'vant/lib/index.less'
// 微信sdk
import wx from 'weixin-js-sdk'
// vue-mete
import Meta from 'vue-meta'
import { getAuth } from '@/wx'
import {DEFAULT_IMG_URL} from '@/const'
import { getSamplesApi, getSampleApi } from '@/api/sample'


Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Meta)

Vue.prototype.$wx = wx

Vue.mixin({
  data() {
    return {
      userInfo: {
        city: '',
        headimgurl: '',
        id: '',
        mobile: '',
        name: '',
        nickname: '',
        openid: '',
        role: ''
      },
      default_img_url: DEFAULT_IMG_URL
    }
  },
  // 抽取公共方法
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    initUser() {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (!userInfo || !userInfo.openid || !userInfo.id) {
        Dialog.confirm({
          message: '请重新授权登录',
        }).then(() => {
          getAuth()
        })
      } else {
        this.userInfo = userInfo
      }
    },


  }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
