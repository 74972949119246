import axios from 'axios'

const request = axios.create({
  baseURL: 'http://129.211.173.188:8000/',
})

// 获取微信配置
export function getWxConfigApi(params) {
  return request({
    method: 'GET',
    url: '/wx/get-config',
    params
  })
}

// 用户授权
export function getUserInfoApi(params) {
  return request({
    method: 'GET',
    url: '/wx/user-info/',
    params
  })
}

// 校验用户信息
export function checkAuthApi(data) {
  return request({
    method: 'POST',
    url: '/wx/check-auth',
    data
  })
}
