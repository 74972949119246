import axios from 'axios'

const request = axios.create({
  baseURL: 'http://wx-api.knoindx.com'
})

const request2 = axios.create({
  baseURL: 'http://221.178.157.122:206'
})

// 创建sample
export function createSampleApi(data) {
  return request({
    method: 'POST',
    url: '/samples/',
    data
  })
}

// 提交sample到公司
export function submitSampleApi(data) {
  return request2({
    method: 'POST',
    url: '/mngs/records/',
    data
  })
}

// 更新sample到公司
export function updateSampleApi(id, data) {
  return request2({
    method: 'PATCH',
    url: `/mngs/records/${id}/`,
    data
  })
}

// 修改sample
export function modifySampleApi(id, data) {
  return request({
    method: 'PATCH',
    url: `/samples/${id}/`,
    data
  })
}

// 修改doctor
export function modifyDoctorApi(id, data) {
  return request({
    method: 'PATCH',
    url: `/doctors/${id}/`,
    data
  })
}
// 获取sample
export function getSamplesApi(params) {
  return request({
    method: 'GET',
    url: '/samples/',
    params
  })
}

export function getSampleApi(id) {
  return request({
    method: 'GET',
    url: `/samples/${id}/`
  })
}

// 上传附件
export function attachCreateApi(file) {
  let data = new FormData();
  data.append('img', file)
  return request({
    header: { 'Content-Type': 'multipart/form-data' },
    method: 'POST',
    url: '/attachs/',
    data
  })
}

// 删除
export function attachDeleteApi(id) {
  return request({
    method: 'DELETE',
    url: `/attachs/${id}/`,
  })
}

// aliOssAuth
export function ossAuthApi() {
  return request({
    method: 'GET',
    url: `/oss-auth/`,
  })
}
