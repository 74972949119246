import Vue from 'vue'
import VueRouter from 'vue-router'
import { setUserInfo, getAuth, checkUserInfo } from '@/wx'
import { getUserInfoApi, checkAuthApi } from '@/api/weixin'
import { Dialog } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SampleAdd',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/SampleAdd')
  },
  {
    path: '/sample-display',
    name: 'SampleDisplay',
    component: () => import('@/views/SampleDisplay')
  },
  {
    path: '/sample-modify',
    name: 'SampleModify',
    component: () => import('@/views/SampleModify')
  },
  {
    path: '/sample-list',
    name: 'SampleList',
    component: () => import('@/views/SampleList')
  },
  {
    path: '/doctor-add',
    name: 'DoctorAdd',
    component: () => import('@/views/DoctorAdd')
  },
  {
    path: '/doctor-modify',
    name: 'DoctorModify',
    component: () => import('@/views/DoctorModify')
  },
  {
    path: '/doctor-list',
    name: 'DoctorList',
    component: () => import('@/views/DoctorList')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('@/views/feedback')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
router.beforeEach((to, from, next) => {
  const noAuthPage = ['Error', 'SampleDisplay']
  if (noAuthPage.includes(to.name)) {
    next() // 执行完next还会往下执行，所以要用else
  } else {
    // 1.微信回调打开的页面
    if ('code' in to.query) {
      const code = localStorage.getItem('code')
      const queryCode = to.query.code
      if (code !== queryCode) {
        // 根据code向后端发请求获得用户信息，存到sessionStorage
        getUserInfoApi({ code: queryCode }).then(res => {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          localStorage.setItem('code', queryCode)
          next()
        }).catch(err => {
          Dialog.alert({
            message: JSON.stringify(err.response.data),
          }).then(() => {
            next({ name: 'Error' })
          })
        })
      } else {
        next()
      }
      // 2.用户手动打开，检查是否有userInfo
    } else {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (!userInfo || !userInfo.openid) {
        getAuth()
      } else {
        next()
      }
    }
  }

})
export default router
